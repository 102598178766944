import React from "react"
import PropTypes from "prop-types"

const Author = ({ author, desc }) => {
  return (
    <div className="authorbox clearfix">
      <div className="authorbox-header">
        <p className="authorbox-name">About {author}</p>
      </div>
      <div className="authorbox-description">{desc}</div>
    </div>
  )
}

export default Author

Author.propTypes = {
  author: PropTypes.string,
  desc: PropTypes.string,
}

Author.defaultProps = {
  author: "Ha Truong",
  desc: "Ha is a writer, web-developer and keen language learner.",
}
