import React from "react"
import { graphql, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { AiOutlineClockCircle, AiFillFolderOpen } from "react-icons/ai"
import capitalize from "../utils/capitalize"

import Layout from "../components/layout/Layout"
import Seo from "../components/SEO"
import ShareButtons from "../components/ShareButtons"
import Author from "../components/Author"
import SideBar from "../components/SideBar"
import MailSub from "../components/MailSub"
import Alert from "../components/Alert"
import Sentence from "../components/Sentence"
import Conversation from "../components/Conversation"
import Example from "../components/Example"
import Quiz from "../components/Quiz"
import Slider from "../components/Slider"
import Support from "../components/Support"

import { Stack } from "@mui/material"

const GuideTemplate = ({ data, location }) => {
  const { frontmatter, body, tableOfContents, parent } = data.mdx
  const { siteUrl } = data.site.siteMetadata
  const { author, categories, description, tags, title, date, image } =
    frontmatter

  const shortcodes = {
    Alert,
    Conversation,
    Example,
    Sentence,
    Quiz,
    Slider,
  }

  const banner = image ? getImage(image).images.fallback.src : ""

  return (
    <Layout>
      <Seo
        title={title}
        desc={description}
        pathname={location.pathname}
        article
        datePub={date}
        dateMod={parent.modifiedTime}
        banner={banner}
      />
      <div className="blog-container" style={{ margin: "2rem" }}>
        <main id="main">
          {/* Header */}
          <Stack spacing={2}>
            <h1 style={{ fontSize: "var(--s3)" }}>{title}</h1>
            {/* Meta */}
            <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
              <span className="with-icon item-meta">
                <AiFillFolderOpen className="icon" />
                <Link to={`/category/${categories}/`}>
                  {capitalize(categories.toString())}
                </Link>
              </span>
              <span className="with-icon item-meta">
                <AiOutlineClockCircle className="icon" />
                Updated: {parent.modifiedTime}
              </span>
            </Stack>
          </Stack>

          {/* Table of contents */}
          <fieldset className="toc">
            <legend
              style={{
                color: "var(--clr-dark)",
                marginLeft: "auto",
              }}
            >
              TABLE OF CONTENTS
            </legend>
            <ul>
              {tableOfContents.items.map(item => {
                return (
                  <li>
                    <Link to={`${location.pathname}${item.url}`}>
                      {item.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </fieldset>

          {/* Article */}
          <div>
            <article>
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </article>
            {tags &&
              tags.map((tag, index) => {
                return (
                  <ul key={index} style={{ listStyleType: "none" }}>
                    <li className="tags" key={tag.toString()}>
                      <Link to={`/tag/${tag}/`} className="btn">
                        {tag}
                      </Link>
                    </li>
                  </ul>
                )
              })}
            <Support />
            <div className="content-share">
              <h5>Share it!</h5>
              <ShareButtons
                title={title}
                url={`${siteUrl}${location.pathname}`}
                twitterHandle="VietnameseGuide"
              />
            </div>
            <Author author={author} />
            <div
              className="email-list desktop-only"
              style={{ padding: "2rem", marginBottom: "2rem" }}
            >
              <MailSub desktop />
            </div>
            <Link to="/guides" className="btn center-btn">
              View more guides
            </Link>
          </div>
        </main>

        <aside className="sidebar">
          <SideBar />
        </aside>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query getPost($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      slug
      body
      frontmatter {
        author
        categories
        description
        tags
        title
        date(formatString: "MMMM Do, YYYY")
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      tableOfContents
      parent {
        ... on File {
          modifiedTime(formatString: "MMMM Do, YYYY")
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default GuideTemplate
