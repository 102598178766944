import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BiCoffeeTogo } from "react-icons/bi"

const Support = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "red-button.png" }) {
        childImageSharp {
          gatsbyImageData(width: 200)
        }
      }
    }
  `)
  const image = getImage(data.file)
  return (
    <div className="support">
      <h5 style={{ margin: "0 0 1rem" }}>
        Love the content? Buy me a coffee{" "}
        <span>
          <BiCoffeeTogo />
        </span>
      </h5>
      <a
        href="https://www.buymeacoffee.com/vietnameseguide"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GatsbyImage image={image} alt="Buy me a coffee" />
      </a>
    </div>
  )
}

export default Support
